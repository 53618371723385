<template>
  <div>
    <v-row>
      <v-col
        md="6"
        offset-md="3"
      >
        <v-card>
          <v-card-title>Create New Airfield</v-card-title>
          <v-card-text>
            <p>If you run another airfield, then fill in the details here and click save.</p>
            <p>
              Once created you will be switched to your new airfield. Switch between your airfields from your account
              menu in the top right.
            </p>
          </v-card-text>
          <v-card-text>
            <airfield-form
              :on-save="onSave"
              :on-success="onSuccess"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AirfieldForm from "../../../components/AirfieldForm";
import {supabase} from "../../../supabase";
import {mapGetters} from "vuex";

export default {
  components: {
    AirfieldForm
  },

  computed: {
    ...mapGetters({
      userId: "auth/userId",
    }),
  },

  methods: {
    async onSave(formData) {
      const {data, error} = await supabase.functions.invoke("airfields-register", {
        body: {
          type: "airfield",
          airfieldData: {
            name: formData.name,
            icao_code: formData.icaoCode?.toUpperCase() || null,
            website_url: formData.websiteUrl || null,
            pilot_briefing_url: formData.pilotBriefingUrl || null
          },
          userId: this.userId,
        },
      });

      if (error) {
        return console.error(error);
      }

      this.$store.dispatch("auth/switchAirfield", data.id);
    },
    onSuccess() {}
  }
};
</script>
