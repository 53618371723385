<template>
  <validation-observer ref="observer">
    <v-form
      ref="form"
      :disabled="isSubmitting"
      lazy-validation
      @submit.prevent="onSubmit"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
          >
            <v-text-field
              outlined
              label="Airfield Name"
              required
              v-model="airfieldData.name"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="length:4"
          >
            <v-text-field
              outlined
              label="ICAO Code"
              v-model="airfieldData.icaoCode"
              hint="Optional, 4 character code assigned by ICAO"
              persistent-hint
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            rules=""
          >
            <v-text-field
              outlined
              label="Website URL"
              type="url"
              v-model="airfieldData.websiteUrl"
              placeholder="https://example-airfield.com"
              hint="Optional"
              persistent-hint
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            rules=""
          >
            <v-text-field
              outlined
              label="Pilot Briefing URL"
              type="url"
              v-model="airfieldData.pilotBriefingUrl"
              placeholder="https://example-airfield.com/pilots-briefing"
              hint="Optional"
              persistent-hint
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
      </v-row>
      <v-row class="justify-center mb-1">
        <v-btn
          color="primary"
          rounded
          type="submit"
          :loading="isSubmitting"
        >
          Save
        </v-btn>
      </v-row>
    </v-form>
  </validation-observer>
</template>

<script>
import {length, required} from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode
} from "vee-validate";


export default {
  name: "AirfieldForm",
  props: {
    defaultData: {
      type: Object,
      default: () => ({
        name: "",
        icaoCode: "",
        websiteUrl: "",
        pilotBriefingUrl: "",
      })
    },
    onSave: {
      type: Function,
      required: true
    },
    onSuccess: {
      type: Function,
      required: true
    }
  },

  created() {
    setInteractionMode("eager");

    extend("required", {
      ...required,
      message: "Required"
    });
    extend("length", {
      ...length,
      message: "Must be {length} characters"
    });
  },

  components: {
    ValidationProvider,
    ValidationObserver
  },

  data() {
    return {
      isSubmitting: false,
      airfieldData: this.defaultData,
    };
  },

  methods: {
    onSubmit: function () {
      this.isSubmitting = true;
      this.$refs.observer
        .validate()
        .then(async isValid => {
          if (isValid) {
            const data = this.airfieldData;
            await this.onSave(data);
            this.onSuccess();
          }
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },

  }
};
</script>
