<template>
  <div>
    <v-row>
      <v-col
        md="6"
        offset-md="3"
      >
        <v-card>
          <v-card-title>Edit Your Airfield</v-card-title>
          <v-card-text>
            <airfield-form
              :default-data="defaultData()"
              :on-save="onSave"
              :on-success="onSuccess"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AirfieldForm from "../../../components/AirfieldForm";

export default {
  components: {
    AirfieldForm
  },

  methods: {
    defaultData: function () {
      return {
        name: this.$store.state.auth.airfieldDocument?.name || "",
        icaoCode: this.$store.state.auth.airfieldDocument?.icao_code || "",
        websiteUrl: this.$store.state.auth.airfieldDocument?.website_url || "",
        pilotBriefingUrl: this.$store.state.auth.airfieldDocument?.pilot_briefing_url || "",
      };
    },

    async onSave(data) {
      await this.$store.dispatch("auth/updateAirfield", {
        name: data.name,
        icao_code: data.icaoCode?.toUpperCase() || null,
        website_url: data.websiteUrl || null,
        pilot_briefing_url: data.pilotBriefingUrl || null
      });
    },
    onSuccess() {
      this.$router.replace("/admin/dashboard");
    }
  }
};
</script>
